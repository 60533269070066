// Generated by Framer (10960d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, optimizeAppear, optimizeAppearTransformTemplate, RichText, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["zTbLk0roC"];

const serializationHash = "framer-EdbRG"

const variantClassNames = {zTbLk0roC: "framer-v-i1mkfu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transition1 = {damping: 30, delay: 0.1, mass: 1, stiffness: 300, type: "spring"}

const animation = {opacity: 1, rotate: 0, scale: 1.2, transition: transition1, x: 0, y: 0}

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const animation1 = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, transition: transition1, x: 0, y: 0}

const animation2 = {opacity: 1, rotate: 0, scale: 1.2, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, BZg1f0xK7: title ?? props.BZg1f0xK7 ?? "Available for work"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BZg1f0xK7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zTbLk0roC", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-EdbRG", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><MotionDivWithFX {...restProps} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={optimizeAppear("animate", "i1mkfu", animation1, undefined)} className={cx("framer-i1mkfu", className)} data-border data-framer-appear-id={"i1mkfu"} data-framer-name={"Variant 1"} exit={animation} initial={optimizeAppear("initial", "i1mkfu", animation2, undefined)} layoutDependency={layoutDependency} layoutId={"zTbLk0roC"} ref={ref ?? ref1} style={{"--border-bottom-width": "3px", "--border-color": "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102)) /* {\"name\":\"Black/40\"} */", "--border-left-width": "3px", "--border-right-width": "3px", "--border-style": "solid", "--border-top-width": "3px", backdropFilter: "blur(5px)", backgroundColor: "rgba(255, 255, 255, 0.6)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, WebkitBackdropFilter: "blur(5px)", ...style}} transformTemplate={optimizeAppearTransformTemplate("i1mkfu", transformTemplate)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7QW50b24tcmVndWxhcg==", "--framer-font-family": "\"Anton\", \"Anton Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-text-color": "var(--extracted-gdpscs, var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102)))"}}>Available for work</motion.h1></React.Fragment>} className={"framer-1xj93r7"} fonts={["GF;Anton-regular"]} layoutDependency={layoutDependency} layoutId={"aXwFle9ee"} style={{"--extracted-gdpscs": "var(--token-3769711a-c3d5-444b-8a14-c0d7e2492513, rgb(102, 102, 102))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={BZg1f0xK7} verticalAlignment={"top"} withExternalLayout/></MotionDivWithFX></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-EdbRG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EdbRG .framer-k49y1d { display: block; }", ".framer-EdbRG .framer-i1mkfu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 12px 12px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-EdbRG .framer-1xj93r7 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EdbRG .framer-i1mkfu { gap: 0px; } .framer-EdbRG .framer-i1mkfu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-EdbRG .framer-i1mkfu > :first-child { margin-left: 0px; } .framer-EdbRG .framer-i1mkfu > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 198
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"BZg1f0xK7":"title"}
 * @framerImmutableVariables true
 */
const FramerFljERZ4EO: React.ComponentType<Props> = withCSS(Component, css, "framer-EdbRG") as typeof Component;
export default FramerFljERZ4EO;

FramerFljERZ4EO.displayName = "Availability";

FramerFljERZ4EO.defaultProps = {height: 53, width: 198};

addPropertyControls(FramerFljERZ4EO, {BZg1f0xK7: {defaultValue: "Available for work", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerFljERZ4EO, [{family: "Anton", style: "normal", url: "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm0Kx8i4gS7lu.woff2", weight: "400"}])